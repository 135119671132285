<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="services"
            :search="search"
            item-key="id"
            sort-by="id"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Service List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  ref="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="750px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on"
                      >New Service</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.service"
                              label="Service"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close"
                        >Cancel</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="save"
                        >Save</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-b-tooltip.hover
                title="Edit"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
                v-b-tooltip.hover
                title="Delete"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri: "services",
    dialog: false,
    search: "",
    headers: [
      { text: "ID", align: "start", sortable: false, value: "id" },
      { text: "Service", value: "service", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    services: [],
    editedIndex: -1,
    editedItem: {
      id: 0,
      service: ""
    },
    defaultItem: {
      id: 0,
      service: ""
    }
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Service" : "Edit Service";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordList('/'+this.uri, function(records){
          _self.services = records
      });
    },

    editItem(item) {
      this.editedIndex = this.services.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = this.contacts.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          this.services.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.services[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.services.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      }
      this.close();
    }
    
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Services" }]);
    setTimeout(() => {
      this.$refs.search.focus();
    }, 500);
  }
};
</script>
